import { Component, Input } from '@angular/core';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatListItemTitle } from '@angular/material/list';
import { RedemptionResponse } from '../../../core/Interfaces/Http/redemption-response';

@Component({
  selector: 'app-notfound',
  standalone: true,
  imports: [MatCard, MatCardContent, MatListItemTitle],
  templateUrl: './notfound.component.html',
  styleUrl: './notfound.component.scss',
})
export class NotfoundComponent {
  @Input() redemption!: RedemptionResponse;
}
