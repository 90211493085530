import { Injectable } from '@angular/core';
import { RedemptionResponse } from '../Interfaces/Http/redemption-response';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, ReplaySubject, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RedemptionService {
  private api: string = environment.api_url;
  private env: string = environment.env;
  private redemptions: ReplaySubject<RedemptionResponse> = new ReplaySubject();
  constructor(private http: HttpClient) {
    if (this.env === 'dev' || this.env === 'alpha') {
      this.searchRedemption('R-0151096-D53');
      this.searchRedemption('00064300432953045286');
      this.searchRedemption('000643fail5286');
      this.searchRedemption('R-0135678-E9C');
      this.searchRedemption('R-0150245-957');
    }
  }

  searchRedemption(id: string): void {
    this.http
      .get<RedemptionResponse>(
        this.api + '/api/redemption/warehouse/V2/items/' + id,
      )
      .pipe(catchError(this.handleError))
      .subscribe((response) => {
        this.redemptions.next(response);
      });
  }

  getRedemptions(): ReplaySubject<RedemptionResponse> {
    return this.redemptions;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error,
      );
    }
    return throwError(
      () => new Error('Something bad happened; please try again later.'),
    );
  }
}
