import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatListItemIcon } from '@angular/material/list';
import { ShippingMethodPipe } from '../../../core/pipes/shipping-method.pipe';
import { Stock } from '../../../core/Interfaces/Http/redemption-response';

@Component({
  selector: 'app-stock-item',
  standalone: true,
  imports: [MatIcon, MatListItemIcon, ShippingMethodPipe],
  templateUrl: './stock-item.component.html',
  styleUrl: './stock-item.component.scss',
})
export class StockItemComponent {
  @Input() stock!: Stock;
}
