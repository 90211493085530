export enum ShippingMethodEnum {
  NoShippingMethod = 'noShippingMethod',
  DHL = 'dhl',
  MyPackHome = 'my_pack_home',
  SmallPackage = 'smallPackage',
  SmallPackageV2 = 'smallPackage_v2',
  Package = 'package',
  PackageV2 = 'package_v2',
  PackageV3 = 'package_v3',
  PackageLarge = 'package_large',
  PackageLargeV2 = 'package_large_v2',
  PackageLargeV3 = 'package_large_v3',
  ExpressPackage = 'expressPackage',
  ThirdParty = 'thirdParty',
  Digital = 'digital',
}
