import { Injectable } from '@angular/core';
import { Settings } from '../Interfaces/settings';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly currentSettings: BehaviorSubject<Settings>;
  constructor() {
    const settingsString: string | null = localStorage.getItem('settings');
    let settings: Settings = this.generateSettings();
    if (settingsString !== null) {
      settings = JSON.parse(settingsString);
    }
    this.currentSettings = new BehaviorSubject<Settings>(settings);

    // Save settings on change.
    this.currentSettings.subscribe((value) => {
      localStorage.setItem('settings', JSON.stringify(value));
    });
  }

  private generateSettings(): Settings {
    return {
      autofocus: false,
      selectedCamera: undefined,
      redemptionQueSize: 10,
    };
  }

  getValue(): Settings {
    return this.currentSettings.getValue();
  }

  getSubject(): Observable<Settings> {
    return this.currentSettings;
  }

  update(settings: Settings): void {
    this.currentSettings.next(settings);
  }

  updateAutofocus(value: boolean) {
    const settings = this.getValue();
    settings.autofocus = value;
    this.update(settings);
  }

  updateCamera(value: MediaDeviceInfo) {
    const settings = this.getValue();
    settings.selectedCamera = value;
    this.update(settings);
  }

  updateQueSize(value: number) {
    const settings = this.getValue();
    settings.redemptionQueSize = value;
    this.update(settings);
  }

  reset(): void {
    this.currentSettings.next(this.generateSettings());
  }
}
