import { Component, OnDestroy } from '@angular/core';
import { Settings } from '../../../core/Interfaces/settings';
import { SettingsService } from '../../../core/services/settings.service';
import {
  MatFormField,
  MatInput,
  MatLabel,
  MatPrefix,
  MatSuffix,
} from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatCard } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-settings-redemptions-log-size',
  standalone: true,
  imports: [
    MatInput,
    MatSuffix,
    MatPrefix,
    MatLabel,
    MatIcon,
    MatCard,
    FormsModule,
    MatFormField,
    MatCheckbox,
  ],
  templateUrl: './redemptions-log-size.component.html',
  styleUrl: './redemptions-log-size.component.scss',
})
export class RedemptionsLogSizeComponent implements OnDestroy {
  sub;
  settings: Settings | undefined;

  constructor(private settingsService: SettingsService) {
    this.sub = this.settingsService.getSubject().subscribe((value) => {
      this.settings = value;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  change() {
    if (!this.settings) {
      return;
    }
    if (this.settings.redemptionQueSize) {
      this.settingsService.updateQueSize(this.settings.redemptionQueSize);
    }
  }
}
