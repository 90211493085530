import { Component } from '@angular/core';
import { RedemptionService } from '../../core/services/redemption.service';
import { RedemptionsComponent } from '../../components/redemptions/redemptions.component';
import { CamReaderComponent } from '../../components/cam-reader/cam-reader.component';
import { BarReaderComponent } from '../../components/bar-reader/bar-reader.component';
import { MatFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-reader',
  standalone: true,
  imports: [
    RedemptionsComponent,
    CamReaderComponent,
    BarReaderComponent,
    MatFabButton,
    MatIcon,
    NgIf,
    RouterLink,
  ],
  templateUrl: './reader.component.html',
  styleUrl: './reader.component.scss',
})
export class ReaderComponent {
  camState: boolean = false;
  constructor(private redemptionService: RedemptionService) {}

  protected camStateChange() {
    this.camState = !this.camState;
  }
}
