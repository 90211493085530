import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewEncapsulation, Input, Output, ViewChild, NgModule } from '@angular/core';
import Quagga from '@ericblade/quagga2';
import defaultsDeep from 'lodash.defaultsdeep';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
const _c0 = ["BarcodeScanner"];
function BarcodeScannerLivestreamOverlayComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵlistener("click", function BarcodeScannerLivestreamOverlayComponent_div_2_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.hide());
    });
    i0.ɵɵtext(1, " X ");
    i0.ɵɵelementEnd();
  }
}
const DEFAULT_CONFIG = {
  inputStream: {
    name: 'Live',
    type: 'LiveStream',
    target: null,
    constraints: {
      width: {
        min: 640
      },
      height: {
        min: 480
      },
      aspectRatio: {
        min: 1,
        max: 2
      },
      facingMode: 'environment' // or user
    },
    singleChannel: false // true: only the red color-channel is read
  },
  locator: {
    patchSize: 'medium',
    halfSample: true
  },
  locate: true,
  numOfWorkers: 4,
  decoder: {
    readers: ['code_128_reader']
  }
};
const BARCODE_TYPES = ['code_128', 'code_39', 'code_39_vin', 'ean', 'ean_extended', 'ean_8', 'upc', 'upc_e', 'codabar', 'i2of5', '2of5', 'code_93'];
function mapToReader(value) {
  if (typeof value === 'string') {
    checkBarCodeType(value);
    return [mapToBarcodeType(value)];
  } else {
    return value.map(val => {
      checkBarCodeType(val);
      return mapToBarcodeType(val);
    });
  }
}
function checkBarCodeType(value) {
  if (!BARCODE_TYPES.some(t => t === value)) {
    throw new Error(`This barcode type '${value}' is not valid.`);
  }
}
function mapToBarcodeType(value) {
  return `${value}_reader`;
}
class BarcodeScannerLivestreamComponent {
  constructor() {
    this.maxWidth = '100%';
    this._valueChanges = new Subject();
    // Outputs
    this.valueChanges = new EventEmitter();
    this.started = new EventEmitter();
    this._started = false;
    this._destroyed = new Subject();
    this._valueChanges.pipe(takeUntil(this._destroyed), filter(result => {
      const errors = result.codeResult.decodedCodes.filter(_ => _.error !== undefined).map(_ => _.error);
      const median = this._getMedian(errors);
      //Filter result when median and/or threshold parameters are provided
      //Good result for code_128 : median = 0.08 and threshold = 0.1
      return !this.errorFilter || !(this.errorFilter.median && median > this.errorFilter.median || this.errorFilter.threshold && errors.some(err => err > this.errorFilter.threshold));
    })).subscribe(result => {
      const drawingCtx = Quagga.canvas.ctx.overlay;
      Quagga.ImageDebug.drawPath(result.line, {
        x: 'x',
        y: 'y'
      }, drawingCtx, {
        color: 'red',
        lineWidth: 3
      });
      this.valueChanges.next(result);
    });
  }
  set torch(value) {
    const track = Quagga.CameraAccess.getActiveTrack();
    if (track) {
      track.applyConstraints({
        advanced: [{
          torch: value
        }]
      });
    }
  }
  get _maxWidth() {
    return this.maxWidth ? `${this.maxWidth}` : 'auto';
  }
  get _maxHeight() {
    return this.maxHeight ? `${this.maxHeight}` : 'auto';
  }
  get isStarted() {
    return this._started;
  }
  ngOnDestroy() {
    this.stop();
    this._destroyed.next(true);
    this._destroyed.complete();
  }
  ngOnChanges() {
    this.restart();
  }
  _init() {
    return new Promise((resolve, reject) => {
      Quagga.onProcessed(result => this.onProcessed(result));
      Quagga.onDetected(result => this.onDetected(result));
      this.configQuagga = defaultsDeep({}, this.config, DEFAULT_CONFIG);
      this.configQuagga.inputStream.target = this.barcodeScanner.nativeElement;
      if (this.type) {
        this.configQuagga.decoder.readers = mapToReader(this.type);
      }
      if (this.deviceId) {
        this.configQuagga.inputStream.constraints.deviceId = this.deviceId;
      }
      Quagga.init(this.configQuagga, err => {
        if (err) {
          console.log(err);
          return reject(err);
        }
        resolve();
      });
    });
  }
  _getMedian(arr) {
    arr.sort((a, b) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1)
      // Odd length
      return arr[half];
    return (arr[half - 1] + arr[half]) / 2.0;
  }
  async start() {
    if (!this._started) {
      await this._init();
      Quagga.start();
      this._started = true;
      this.started.next(true);
    }
  }
  stop() {
    if (this._started) {
      Quagga.stop();
      this._started = false;
      this.started.next(false);
    }
  }
  restart() {
    if (this._started) {
      this.stop();
      this.start();
    }
  }
  onProcessed(result) {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;
    if (result) {
      if (result.boxes) {
        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width'), 10), parseInt(drawingCanvas.getAttribute('height'), 10));
        result.boxes.filter(box => {
          return box !== result.box;
        }).forEach(box => {
          Quagga.ImageDebug.drawPath(box, {
            x: 0,
            y: 1
          }, drawingCtx, {
            color: 'green',
            lineWidth: 2
          });
        });
      }
      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, {
          x: 0,
          y: 1
        }, drawingCtx, {
          color: '#00F',
          lineWidth: 2
        });
      }
    }
  }
  onDetected(result) {
    this._valueChanges.next(result);
  }
}
BarcodeScannerLivestreamComponent.ɵfac = function BarcodeScannerLivestreamComponent_Factory(t) {
  return new (t || BarcodeScannerLivestreamComponent)();
};
BarcodeScannerLivestreamComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BarcodeScannerLivestreamComponent,
  selectors: [["barcode-scanner-livestream"]],
  viewQuery: function BarcodeScannerLivestreamComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.barcodeScanner = _t.first);
    }
  },
  inputs: {
    type: "type",
    deviceId: "deviceId",
    maxWidth: "maxWidth",
    maxHeight: "maxHeight",
    config: "config",
    errorFilter: "errorFilter",
    torch: "torch"
  },
  outputs: {
    valueChanges: "valueChanges",
    started: "started"
  },
  features: [i0.ɵɵNgOnChangesFeature],
  decls: 4,
  vars: 13,
  consts: [["BarcodeScanner", ""], [1, "scanner", 3, "hidden"], [1, "drawingBuffer"]],
  template: function BarcodeScannerLivestreamComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 1, 0);
      i0.ɵɵelement(2, "video")(3, "canvas", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵstyleProp("max-height", ctx._maxHeight)("max-width", ctx._maxWidth);
      i0.ɵɵproperty("hidden", !ctx.isStarted);
      i0.ɵɵadvance(2);
      i0.ɵɵstyleProp("max-height", ctx._maxHeight)("max-width", ctx._maxWidth);
      i0.ɵɵadvance();
      i0.ɵɵstyleProp("max-height", ctx._maxHeight)("max-width", ctx._maxWidth);
    }
  },
  styles: [".scanner{position:relative}.scanner video,.scanner canvas{width:100%;height:100%}.scanner canvas.drawingBuffer{position:absolute;left:0;top:0}\n"],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BarcodeScannerLivestreamComponent, [{
    type: Component,
    args: [{
      selector: 'barcode-scanner-livestream',
      encapsulation: ViewEncapsulation.None,
      template: "<div\n  #BarcodeScanner\n  class=\"scanner\"\n  [hidden]=\"!isStarted\"\n  [style.max-height]=\"_maxHeight\"\n  [style.max-width]=\"_maxWidth\"\n>\n  <video [style.max-height]=\"_maxHeight\" [style.max-width]=\"_maxWidth\"></video>\n  <canvas\n    [style.max-height]=\"_maxHeight\"\n    [style.max-width]=\"_maxWidth\"\n    class=\"drawingBuffer\"\n  ></canvas>\n</div>\n",
      styles: [".scanner{position:relative}.scanner video,.scanner canvas{width:100%;height:100%}.scanner canvas.drawingBuffer{position:absolute;left:0;top:0}\n"]
    }]
  }], function () {
    return [];
  }, {
    type: [{
      type: Input
    }],
    deviceId: [{
      type: Input
    }],
    maxWidth: [{
      type: Input
    }],
    maxHeight: [{
      type: Input
    }],
    config: [{
      type: Input
    }],
    errorFilter: [{
      type: Input
    }],
    torch: [{
      type: Input
    }],
    valueChanges: [{
      type: Output
    }],
    started: [{
      type: Output
    }],
    barcodeScanner: [{
      type: ViewChild,
      args: ['BarcodeScanner']
    }]
  });
})();
class BarcodeScannerLivestreamModule {}
BarcodeScannerLivestreamModule.ɵfac = function BarcodeScannerLivestreamModule_Factory(t) {
  return new (t || BarcodeScannerLivestreamModule)();
};
BarcodeScannerLivestreamModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: BarcodeScannerLivestreamModule
});
BarcodeScannerLivestreamModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BarcodeScannerLivestreamModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [BarcodeScannerLivestreamComponent],
      exports: [BarcodeScannerLivestreamComponent]
    }]
  }], null, null);
})();
class BarcodeScannerLivestreamOverlayComponent {
  constructor() {
    this._started = false;
    this.width = '90vw';
    this.maxWidth = '640px';
    this.valueChanges = new EventEmitter();
    this.started = new EventEmitter();
    this._showScanner = false;
  }
  get isStarted() {
    return this._started;
  }
  get showScanner() {
    return this._showScanner;
  }
  ngOnDestroy() {
    this.scanner.stop();
  }
  show() {
    this._showScanner = true;
    this.scanner.start();
  }
  hide() {
    this._showScanner = false;
    this.scanner.stop();
  }
  onStarted(value) {
    this._started = value;
    this.started.next(value);
  }
  onValueChanges(result) {
    this.valueChanges.next(result);
  }
}
BarcodeScannerLivestreamOverlayComponent.ɵfac = function BarcodeScannerLivestreamOverlayComponent_Factory(t) {
  return new (t || BarcodeScannerLivestreamOverlayComponent)();
};
BarcodeScannerLivestreamOverlayComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BarcodeScannerLivestreamOverlayComponent,
  selectors: [["barcode-scanner-livestream-overlay"]],
  viewQuery: function BarcodeScannerLivestreamOverlayComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(BarcodeScannerLivestreamComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.scanner = _t.first);
    }
  },
  inputs: {
    type: "type",
    deviceId: "deviceId",
    width: "width",
    maxWidth: "maxWidth",
    height: "height",
    maxHeight: "maxHeight",
    config: "config"
  },
  outputs: {
    valueChanges: "valueChanges",
    started: "started"
  },
  decls: 4,
  vars: 13,
  consts: [[1, "barcode-scanner-livestream-overlay", 3, "hidden"], [1, "barcode-scanner-livestream-overlay-content"], ["class", "barcode-scanner-livestream-overlay-close", 3, "click", 4, "ngIf"], [3, "valueChanges", "started", "type", "deviceId", "config"], [1, "barcode-scanner-livestream-overlay-close", 3, "click"]],
  template: function BarcodeScannerLivestreamOverlayComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
      i0.ɵɵtemplate(2, BarcodeScannerLivestreamOverlayComponent_div_2_Template, 2, 0, "div", 2);
      i0.ɵɵelementStart(3, "barcode-scanner-livestream", 3);
      i0.ɵɵlistener("valueChanges", function BarcodeScannerLivestreamOverlayComponent_Template_barcode_scanner_livestream_valueChanges_3_listener($event) {
        return ctx.onValueChanges($event);
      })("started", function BarcodeScannerLivestreamOverlayComponent_Template_barcode_scanner_livestream_started_3_listener($event) {
        return ctx.onStarted($event);
      });
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("hidden", !ctx.showScanner);
      i0.ɵɵadvance();
      i0.ɵɵstyleProp("width", ctx.width)("max-width", ctx.maxWidth)("height", ctx.height)("max-height", ctx.maxHeight);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.isStarted);
      i0.ɵɵadvance();
      i0.ɵɵproperty("type", ctx.type)("deviceId", ctx.deviceId)("config", ctx.config);
    }
  },
  dependencies: [i1.NgIf, BarcodeScannerLivestreamComponent],
  styles: [".barcode-scanner-livestream-overlay[_ngcontent-%COMP%]{overflow:hidden;position:fixed;inset:0;width:100%;background-color:#0000004d;z-index:1000}.barcode-scanner-livestream-overlay[_ngcontent-%COMP%]   .barcode-scanner-livestream-overlay-content[_ngcontent-%COMP%]{top:50%;position:absolute;left:50%;transform:translate(-50%,-50%)}.barcode-scanner-livestream-overlay[_ngcontent-%COMP%]   .barcode-scanner-livestream-overlay-content[_ngcontent-%COMP%]   .barcode-scanner-livestream-overlay-close[_ngcontent-%COMP%]{position:absolute;right:0;padding:.5rem;width:1.5rem;height:1.5rem;line-height:1.5rem;text-align:center;background-color:#fff;cursor:pointer;border:2px solid black;font-size:1.3rem;margin:-1rem;border-radius:2rem;z-index:100;box-sizing:content-box}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BarcodeScannerLivestreamOverlayComponent, [{
    type: Component,
    args: [{
      selector: 'barcode-scanner-livestream-overlay',
      template: "<div class=\"barcode-scanner-livestream-overlay\" [hidden]=\"!showScanner\">\n  <div\n    class=\"barcode-scanner-livestream-overlay-content\"\n    [style.width]=\"width\"\n    [style.max-width]=\"maxWidth\"\n    [style.height]=\"height\"\n    [style.max-height]=\"maxHeight\"\n  >\n    <div\n      class=\"barcode-scanner-livestream-overlay-close\"\n      *ngIf=\"isStarted\"\n      (click)=\"hide()\"\n    >\n      X\n    </div>\n    <barcode-scanner-livestream\n      [type]=\"type\"\n      [deviceId]=\"deviceId\"\n      [config]=\"config\"\n      (valueChanges)=\"onValueChanges($event)\"\n      (started)=\"onStarted($event)\"\n    ></barcode-scanner-livestream>\n  </div>\n</div>\n",
      styles: [".barcode-scanner-livestream-overlay{overflow:hidden;position:fixed;inset:0;width:100%;background-color:#0000004d;z-index:1000}.barcode-scanner-livestream-overlay .barcode-scanner-livestream-overlay-content{top:50%;position:absolute;left:50%;transform:translate(-50%,-50%)}.barcode-scanner-livestream-overlay .barcode-scanner-livestream-overlay-content .barcode-scanner-livestream-overlay-close{position:absolute;right:0;padding:.5rem;width:1.5rem;height:1.5rem;line-height:1.5rem;text-align:center;background-color:#fff;cursor:pointer;border:2px solid black;font-size:1.3rem;margin:-1rem;border-radius:2rem;z-index:100;box-sizing:content-box}\n"]
    }]
  }], null, {
    type: [{
      type: Input
    }],
    deviceId: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    maxWidth: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    maxHeight: [{
      type: Input
    }],
    config: [{
      type: Input
    }],
    valueChanges: [{
      type: Output
    }],
    started: [{
      type: Output
    }],
    scanner: [{
      type: ViewChild,
      args: [BarcodeScannerLivestreamComponent]
    }]
  });
})();
class BarcodeScannerLivestreamOverlayModule {}
BarcodeScannerLivestreamOverlayModule.ɵfac = function BarcodeScannerLivestreamOverlayModule_Factory(t) {
  return new (t || BarcodeScannerLivestreamOverlayModule)();
};
BarcodeScannerLivestreamOverlayModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: BarcodeScannerLivestreamOverlayModule
});
BarcodeScannerLivestreamOverlayModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, BarcodeScannerLivestreamModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BarcodeScannerLivestreamOverlayModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, BarcodeScannerLivestreamModule],
      declarations: [BarcodeScannerLivestreamOverlayComponent],
      exports: [BarcodeScannerLivestreamOverlayComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-barcode-scanner
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BarcodeScannerLivestreamComponent, BarcodeScannerLivestreamModule, BarcodeScannerLivestreamOverlayComponent, BarcodeScannerLivestreamOverlayModule };
