import { Component, Input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { MatFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-thumbnail',
  standalone: true,
  imports: [NgOptimizedImage, MatFabButton, MatIcon],
  templateUrl: './thumbnail.component.html',
  styleUrl: './thumbnail.component.scss',
})
export class ThumbnailComponent {
  @Input() thumbnail!: string;
  view: boolean = false;
}
