<mat-card class="m-4">
  <mat-card-content>
    <div class="flex flex-justify flex-wrap">
      <span matListItemTitle class="w-full md:w-1/2">{{redemption.redemption.postNordTrackingID}}</span>
      <span class="w-full md:w-1/2">
            <div class="flex flex-justify flex-wrap">
              <div class="w-full md:w-1/2">{{redemption.redemption.address}}</div>
              <div class="w-full md:w-1/2">{{redemption.redemption.redeemedAt*1000 | date:'H:mm:ss MM.d.y'}}</div>
            </div>
          </span>
    </div>
    <mat-list role="list" *ngFor="let product of redemption.products">
      <app-product-item [product]="product"></app-product-item>
    </mat-list>
  </mat-card-content>
</mat-card>
