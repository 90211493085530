<mat-list-item role="listitem">

  <app-thumbnail [thumbnail]="product.images.thumbnail"></app-thumbnail>

  <div class="flex justify-between flex-wrap">
    <div class="w-full md:w-1/2">{{product.name}}</div>
    <div class="w-full md:w-1/2">
      <div class="flex">
        <div class="w-1/3">{{product.quantity}} :kpl</div>
        <div class="w-1/3">{{product.shippingMethod | shippingMethod}}</div>
<!--        <div class="w-1/3 relative md:visible invisible">-->
<!--          <div class="absolute bottom-0 right-0">-->
<!--            <img width="60" height="100" ngSrc="{{product.images.thumbnail}}" alt="thumbnail">-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</mat-list-item>

@if (product.variationData) {
  <mat-list-item role="listitem">
    <div class="flex">
      <div class="basis-1/16">
        <mat-icon matListItemIcon>keyboard_arrow_right</mat-icon>
      </div>
      @if (product.variationData.color) {
        <div class="basis-1/8">Väri: {{product.variationData.color.description}}</div>
      }
      @if (product.variationData.size) {
        <div class="basis-1/8">Koko: {{product.variationData.size.size}}, {{product.variationData.size.description}}</div>
      }
    </div>
  </mat-list-item>
}
@if (!product.variationData) {
  <div *ngFor="let stock of product.stock">
    <mat-list-item role="listitem">
      <app-stock-item [stock]="stock"></app-stock-item>
    </mat-list-item>
  </div>
}
