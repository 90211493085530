import { Component } from '@angular/core';
import { RedemptionResponse } from '../../core/Interfaces/Http/redemption-response';
import { RedemptionService } from '../../core/services/redemption.service';
import {
  AsyncPipe,
  DatePipe,
  JsonPipe,
  NgForOf,
  NgIf,
  NgOptimizedImage,
} from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { ShippingMethodPipe } from '../../core/pipes/shipping-method.pipe';
import {
  MatList,
  MatListItem,
  MatListItemIcon,
  MatListItemTitle,
} from '@angular/material/list';
import { MatIcon } from '@angular/material/icon';
import { DeliveryComponent } from './delivery/delivery.component';
import { FetchComponent } from './fetch/fetch.component';
import { NotfoundComponent } from './notfound/notfound.component';

@Component({
  selector: 'app-redemptions',
  standalone: true,
  imports: [
    NgForOf,
    AsyncPipe,
    NgIf,
    JsonPipe,
    MatCard,
    MatCardContent,
    ShippingMethodPipe,
    DatePipe,
    NgOptimizedImage,
    MatList,
    MatListItem,
    MatListItemTitle,
    MatIcon,
    MatListItemIcon,
    DeliveryComponent,
    FetchComponent,
    NotfoundComponent,
  ],
  templateUrl: './redemptions.component.html',
  styleUrl: './redemptions.component.scss',
})
export class RedemptionsComponent {
  maxLength: number = 10;
  redemptions: RedemptionResponse[] = [];
  constructor(private redemptionService: RedemptionService) {
    this.redemptionService.getRedemptions().subscribe({
      next: (value) => {
        this.redemptions.push(value);
        if (this.redemptions.length > 10) {
          this.redemptions.splice(0, 1);
        }
      },
    });
  }
}
