import { Component, Input } from '@angular/core';
import { NgForOf, NgOptimizedImage } from '@angular/common';
import { Product } from '../../../core/Interfaces/Http/redemption-response';
import { MatIcon } from '@angular/material/icon';
import { MatListItem, MatListItemIcon } from '@angular/material/list';
import { StockItemComponent } from '../stock-item/stock-item.component';
import { ShippingMethodPipe } from '../../../core/pipes/shipping-method.pipe';
import { ThumbnailComponent } from '../thumbnail/thumbnail.component';

@Component({
  selector: 'app-product-item',
  standalone: true,
  imports: [
    NgOptimizedImage,
    MatIcon,
    MatListItem,
    MatListItemIcon,
    NgForOf,
    StockItemComponent,
    ShippingMethodPipe,
    ThumbnailComponent,
  ],
  templateUrl: './product-item.component.html',
  styleUrl: './product-item.component.scss',
})
export class ProductItemComponent {
  @Input() product!: Product;
}
