<mat-form-field class="w-screen">
<!--  <mat-label>{{barcodeValue}}</mat-label>-->
  <input matInput #myElement [(ngModel)]="barcodeValue" placeholder="Syötä viivakoodi" (change)="inputOnValueChanges()" value="" >
  @if (barcodeValue) {
    <button matSuffix mat-icon-button aria-label="Clear" (click)="barcodeValue=''">
      <mat-icon>close</mat-icon>
    </button>
  }
</mat-form-field>



