<div class="absolute bottom-0 right-0" (click)="view = !view" (keydown)="view = !view" aria-hidden="true">
  <img width="50" height="50" ngSrc="{{thumbnail}}" alt="thumbnail">
</div>

@if (view) {
  <div (click)="view = !view" (keydown)="view = !view" aria-hidden="true">
    <div class="fixed z-50 w-full h-full top-0 left-0 flex items-center justify-center backdrop-blur-sm" >
      <div class="h-2/4 w-2/4">
        <div class="flex content-center">
          <img class="object-cover " src="{{thumbnail}}" alt="thumbnail">
          <div class="relative -top-12 right-2">
            <button mat-fab color="warn">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
}
