import { Component, Input } from '@angular/core';
import { DatePipe, NgForOf, NgOptimizedImage } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import {
  MatList,
  MatListItem,
  MatListItemIcon,
  MatListItemTitle,
} from '@angular/material/list';
import { ShippingMethodPipe } from '../../../core/pipes/shipping-method.pipe';
import { RedemptionResponse } from '../../../core/Interfaces/Http/redemption-response';
import { ProductItemComponent } from '../product-item/product-item.component';
import { StockItemComponent } from '../stock-item/stock-item.component';

@Component({
  selector: 'app-fetch',
  standalone: true,
  imports: [
    DatePipe,
    MatCard,
    MatCardContent,
    MatIcon,
    MatList,
    MatListItem,
    MatListItemIcon,
    MatListItemTitle,
    NgForOf,
    NgOptimizedImage,
    ShippingMethodPipe,
    ProductItemComponent,
    StockItemComponent,
  ],
  templateUrl: './fetch.component.html',
  styleUrl: './fetch.component.scss',
})
export class FetchComponent {
  @Input() redemption!: RedemptionResponse;
}
