import { Component, OnDestroy } from '@angular/core';
import { Settings } from '../../../core/Interfaces/settings';
import { SettingsService } from '../../../core/services/settings.service';
import { MatCard } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-settings-camera',
  standalone: true,
  imports: [MatCard, MatCheckbox, MatLabel, MatSelect, MatOption],
  templateUrl: './camera.component.html',
  styleUrl: './camera.component.scss',
})
export class CameraComponent implements OnDestroy {
  sub: Subscription | undefined;
  settings: Settings | undefined;
  foundDevices: boolean = false;
  selectedDevice: MediaDeviceInfo | undefined;
  selectedDeviceID: string | undefined;
  deviceList: MediaDeviceInfo[] = [];

  constructor(private settingsService: SettingsService) {
    this.loadSettings();
    this.loadDevices().then(() => {
      if (this.selectedDevice === undefined) {
        this.selectDefaultDevice();
      }
      if (this.selectedDevice !== undefined) {
        this.change();
      }
    });
  }

  selectDefaultDevice() {
    if (this.deviceList.length > 0) {
      this.selectedDevice = this.deviceList[0];
      this.selectedDeviceID = this.deviceList[0].deviceId;
    }
  }

  loadDevices() {
    return navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        this.foundDevices = true;
        devices = devices.filter((device) => {
          return device.kind === 'videoinput';
        });
        this.deviceList = devices;
      })
      .catch((err) => {
        console.error(`${err.name}: ${err.message}`);
      });
  }

  loadSettings() {
    this.sub = this.settingsService.getSubject().subscribe((value) => {
      this.settings = value;
      if (this.settings.selectedCamera) {
        this.selectedDevice = this.settings.selectedCamera;
        this.selectedDeviceID = this.settings.selectedCamera.deviceId;
      }
    });
  }

  ngOnDestroy() {
    if (this.sub !== undefined) {
      this.sub.unsubscribe();
    }
  }

  change() {
    if (!this.settings) {
      return;
    }

    this.deviceList.forEach((device) => {
      if (device.deviceId === this.selectedDeviceID) {
        this.selectedDevice = device;
      }
    });

    if (this.selectedDevice) {
      this.settingsService.updateCamera(this.selectedDevice);
    }
  }
}
