import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RedemptionService } from '../../core/services/redemption.service';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { interval } from 'rxjs';
import { SettingsService } from '../../core/services/settings.service';

@Component({
  selector: 'app-bar-reader',
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatIcon,
    MatIconButton,
    MatSuffix,
  ],
  templateUrl: './bar-reader.component.html',
  styleUrl: './bar-reader.component.scss',
})
export class BarReaderComponent implements AfterViewInit {
  @ViewChild('myElement') input!: ElementRef;
  barcodeValue: string = '';
  autofocus: boolean = false;

  constructor(
    private redemptionService: RedemptionService,
    private settingsService: SettingsService,
  ) {
    this.autofocus = this.settingsService.getValue().autofocus;
  }
  inputOnValueChanges() {
    let input = this.barcodeValue;
    input = input.replaceAll('+', '-');
    this.barcodeValue = input;
    this.redemptionService.searchRedemption(input);
    this.barcodeValue = '';
  }
  ngAfterViewInit() {
    // this.redemptionService.searchRedemption('00064300432945800114');
    if (this.autofocus) {
      interval(100).subscribe(() => {
        this.input.nativeElement.focus();
      });
    }
  }
}
